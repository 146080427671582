import { Listbox } from '@headlessui/react';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import GetStartedFormModel from '@/models/GetStartedFormModel';
import { makeDot } from '@/types/Path';
import { monthDateYear, parseDate } from '@/utils/dateFormats';
import {
    isMoreThanTwoWeeks,
    isNextTwoWeeks,
    isNextTwoWeeksFrom,
} from '@/utils/luxonFormats';
import { DateTime } from 'luxon';
import { ChevronDownIcon } from '../common/Icons';
import ThreeDotsLoader from '../loaders/ThreeDotsLoader';

const dot = makeDot<GetStartedFormModel>();

type DeliveryDateSelectProps = {
    data?: any;
    isFetching?: any;
};

const darwinPostalCode = [
    '800',
    '810',
    '812',
    '820',
    '822',
    '828',
    '829',
    '830',
    '832',
    '834',
    '835',
    '836',
    '839',
    '850',
    '852',
    '853',
];

export default function DeliveryDateSelect({
    data,
    isFetching,
}: DeliveryDateSelectProps) {
    const formik = useFormikContext();
    const { shippingPostcode, shippingDateStartedAt } =
        formik.values as Partial<GetStartedFormModel>;
    const { setFieldValue, setFieldTouched } = formik;

    const convertToDateInTimeZone = (dateStr: any, startTime: string) => {
        const dateTimeStr = `${dateStr}T${startTime}`;
        const utcDate = DateTime.fromISO(dateTimeStr, { zone: 'utc' });
        const tzDate = utcDate.setZone('Australia/Sydney').toISO().slice(0, 10);
        return tzDate;
    }

    const getFormattedDateLabel = (dateStr: string, startTime: string) => {
        const convertedDate = convertToDateInTimeZone(dateStr, startTime);
        return monthDateYear.format(parseDate(convertedDate)).replace(',', '');
    }

    const schedules = data?.data
        ?.sort((a: any, b: any) => a.date.localeCompare(b.date))
        ?.reduce((accumulator: any, current: any) => {
            if (!accumulator.find((item: any) => item.date === current.date)) {
                if (
                    darwinPostalCode?.includes(
                        shippingPostcode?.charAt(0) === '0'
                            ? shippingPostcode?.substring(1)
                            : shippingPostcode!!
                    )
                ) {
                    accumulator.push(current);
                } else if (isMoreThanTwoWeeks(data?.data?.[0].date)) {
                    if (
                        isNextTwoWeeksFrom(current?.date, data?.data?.[0].date)
                    ) {
                        accumulator.push(current);
                    }
                } else if (isNextTwoWeeks(current?.date)) {
                    accumulator.push(current);
                }
            }
            return accumulator;
        }, []);

    const handleChange = (key: any) => {
        setFieldValue(dot('shippingDateStartedAt'), key);
    };

    const getTimeForSelectedDate = () => {
        if (!shippingDateStartedAt) return '00:00';
        const selectedDate = shippingDateStartedAt;
        const matchingOption = schedules.find((option: any) => option.date === selectedDate);
        return matchingOption ? matchingOption.start : '00:00';
    }

    useEffect(() => {
        setFieldValue(dot('shippingDateStartedAt'), '');
    }, [setFieldValue, shippingPostcode]);

    return (
        <div>
            <ThreeDotsLoader visible={isFetching} />
            <Listbox
                value={shippingDateStartedAt}
                onChange={handleChange}
                disabled={!schedules?.length}
            >
                <Listbox.Button
                    onBlur={() => setFieldTouched(dot('shippingDateStartedAt'))}
                    className="flex h-10 w-full items-center justify-between border border-secondary px-6 text-xs focus:outline-none focus-visible:ring focus-visible:ring-primary disabled:opacity-40 sm:text-base"
                >
                    {shippingDateStartedAt
                        ? getFormattedDateLabel(shippingDateStartedAt, getTimeForSelectedDate())
                        : 'Select a delivery date'}
                    <ChevronDownIcon />
                </Listbox.Button>
                <Listbox.Options className="max-h-60 w-full select-none overflow-auto border border-secondary bg-white p-2 shadow-block">
                    {schedules?.map((option: any) => (
                        <Listbox.Option
                            key={option?.date}
                            value={option.date}
                            className="flex cursor-pointer items-center justify-between py-2 px-4 text-xs focus:outline-none focus-visible:ring focus-visible:ring-primary highlighted:text-primary checked:font-semibold sm:text-base"
                        >
                            {getFormattedDateLabel(option?.date, option?.start)}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
            {schedules && schedules.length === 0 && (
                <div className="mt-1 text-xs text-error">
                    Sorry, there is no available schedules in your area
                </div>
            )}
        </div>
    );
}
