import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationCard from '@/components/cards/NotificationCard';
import AsSeenOn from '@/components/common/AsSeenOn';
import Button from '@/components/common/Button';
import { GiftBoxIcon } from '@/components/common/Icons';
import Wrapper from '@/components/common/Wrapper';
import ActivateGiftCardForm from '@/components/forms/giftCard/ActivateGiftCardForm';
import GiftCardForm from '@/components/forms/giftCard/GiftCardForm';
import SignInFormModal from '@/components/forms/signIn/SignInFormModal';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import AlertModal from '@/components/modals/AlertModal';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import ActivateGiftCardFormModel, {
    activateGiftCardFormSchema,
} from '@/models/ActivateGiftCardFormModel';
import GiftCardFormModel, {
    giftCardFormSchema,
} from '@/models/GiftCardFormModel';
import LoginFormModel, { loginFormSchema } from '@/models/SignInFormModel';
import { SignInParams, postSignIn } from '@/services/authService';
import {
    useActivateGiftCard,
    useGiftCardSettings,
} from '@/services/giftCardService';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import {
    resetGiftCardsError,
    selectError,
    selectGiftCardsError,
    setError,
    setGiftCardsError,
} from '@/store/giftCards/giftCardsErrorSlice';
import {
    resetGiftCardsSuccess,
    selectSuccess,
    setSuccess,
} from '@/store/giftCards/giftCardsSuccessSlice';
import { useUser } from '@/store/user/userSlice';
import { giftCardsPrivateListen } from '@/utils/broadCastEvent';

const OGImage = `${window.cdn}smo/SMO-gift-card.png`;

const routes = [
    {
        route: '/gift-cards',
        title: 'Gift cards',
    },
];

const anchor = [
    { title: 'Buy a giftcard', route: 'give' },
    { title: 'Use a giftcard', route: 'activate' },
];

export default function GiftCardPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user] = useUser();
    const { filter, code } = useParams();
    const [openLoginModal, setOpenLoginModal] = useState<any>(false);
    const [giftCardAmount, setGiftCardAmount] = useState<any>(5000);
    const [cookie, setCookie, removeCookie] = useCookies([
        'giftCardAmount',
        'giftCardActivationCode',
    ]);
    const giftCardsError = useSelector(selectGiftCardsError);
    const giftCardsErrorMessage = useSelector(selectError);
    const giftCardsActivateSuccess = useSelector(selectSuccess);
    const loading = useSelector(selectLoading);
    const { mutateAsync: activateGiftCard, error: activateGiftCardError } =
        useActivateGiftCard();
    const { mutateAsync: signIn } = useMutation(
        async (payload: SignInParams) => {
            const { data, status } = await postSignIn(payload);
            if (status !== 200) {
                throw Error('Password or username is incorrect');
            }
            return data;
        }
    );
    const { giftCardSettingsResult } = useGiftCardSettings();

    const handleSubmit = useHandleSubmit(
        activateGiftCardFormSchema,
        (v) => activateGiftCard(v),
        ({ helpers }) => {
            removeCookie('giftCardActivationCode', {
                path: '/',
            });
            navigate('/gift-cards/activate');
            helpers.resetForm();
            if (!window.Echo.socketId()) {
                dispatch(setLoading(false));
                dispatch(setSuccess(true));
            }
        },
        dispatch
    );

    const handleSubmitLogin = useHandleSubmit(
        loginFormSchema,
        signIn,
        () => {
            window.location.href = `/gift-cards/activate/${cookie.giftCardActivationCode}`;
        },
        dispatch
    );

    useEffect(() => {
        if (user?.email) {
            giftCardsPrivateListen(user?.id, 'ActivatedGiftCard', '', dispatch);
            if (cookie.giftCardActivationCode) {
                activateGiftCard(cookie.giftCardActivationCode);
            }
        }
    }, [user, dispatch, cookie.giftCardActivationCode, activateGiftCard]);

    useEffect(() => {
        if (activateGiftCardError) {
            dispatch(setGiftCardsError(true));
            dispatch(
                setError(
                    'Sorry, this gift card does not exist or has already been claimed.'
                )
            );
            if (cookie.giftCardActivationCode) {
                removeCookie('giftCardActivationCode', {
                    path: '/',
                });
            }
        }
    }, [
        activateGiftCardError,
        dispatch,
        user,
        cookie.giftCardActivationCode,
        removeCookie,
    ]);

    return (
        <Wrapper>
            <Helmet>
                <title>Gifts card | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Gift the joy of ethical, high-quality, sustainably sourced meat & wild-caught seafood, with a ButcherCrowd gift card. Perfect for any occasion, our gift cards are valid for use on all ButcherCrowd boxes."
                />
                <meta
                    property="og:title"
                    content="Gifts card | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Gift the joy of ethical, high-quality, sustainably sourced meat & wild-caught seafood, with a ButcherCrowd gift card. Perfect for any occasion, our gift cards are valid for use on all ButcherCrowd boxes."
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <ThreeDotsLoader visible={loading} />
            <div className="px-4 pt-8 pb-8 sm:block sm:px-8 lg:mx-7">
                <Breadcrumbs crumbs={routes} />
            </div>
            <div className="flex flex-col items-center px-4">
                <h3 className="text-3xl font-bold text-center sm:text-4xl">
                    Gift ButcherCrowd to Someone You Know
                </h3>
                <p className="w-full pt-6 text-center font-neue text-lg lg:w-[45%]">
                    When you give the gift of ButcherCrowd, you&apos;re not only
                    offering premium meat & wild-caught seafood, you&apos;re
                    giving someone back their time, saving them money and
                    stress.
                </p>
            </div>
            {giftCardSettingsResult?.bonus > 0 && (
                <div
                    className={clsx(
                        'mx-auto mt-6 flex w-fit justify-center bg-gray-100 py-2 px-7',
                        filter === 'activate' && 'hidden'
                    )}
                >
                    <GiftBoxIcon className="mr-5" />
                    <p className="my-auto">
                        Buy a gift card and we&apos;ll add{' '}
                        <span className="text-primary">
                            {giftCardSettingsResult.bonus / 100}% of it&apos;s
                            value
                        </span>
                    </p>
                </div>
            )}
            <div className="flex flex-wrap justify-center mt-9 space-x-20 sm:mt-16">
                {anchor.map((e) => (
                    <button
                        key={e.title}
                        type="button"
                        className={clsx(
                            'border-b-2 border-white py-1.5 font-agrandir text-sm font-bold text-black transition-colors hover:border-primary',
                            filter === e.route && 'border-b-2 !border-primary',
                            !filter &&
                                e.route === 'give' &&
                                'border-b-2 !border-primary'
                        )}
                        onClick={() => {
                            navigate(`/gift-cards/${e.route}`);
                        }}
                    >
                        {e.title}
                    </button>
                ))}
            </div>
            <div role="separator" className="h-px bg-gray-200 shrink-0" />
            <div className={clsx(filter === 'activate' && 'hidden')}>
                <Formik<Partial<GiftCardFormModel>>
                    initialValues={{}}
                    validationSchema={giftCardFormSchema}
                    onSubmit={() => {
                        setCookie('giftCardAmount', giftCardAmount, {
                            maxAge: 3600 * 24,
                            path: '/',
                        });
                        navigate('/gift-cards/personalise');
                    }}
                >
                    <GiftCardForm
                        amount={giftCardAmount}
                        setAmount={setGiftCardAmount}
                    />
                </Formik>
            </div>
            <div className={clsx((!filter || filter === 'give') && 'hidden')}>
                <Formik<Partial<ActivateGiftCardFormModel>>
                    enableReinitialize
                    initialValues={{
                        activationCode: code || '',
                    }}
                    validationSchema={activateGiftCardFormSchema}
                    onSubmit={
                        user.email
                            ? handleSubmit
                            : (values) => {
                                  setOpenLoginModal(true);
                                  setCookie(
                                      'giftCardActivationCode',
                                      values.activationCode,
                                      {
                                          maxAge: 3600 * 24,
                                          path: '/',
                                      }
                                  );
                              }
                    }
                >
                    <ActivateGiftCardForm />
                </Formik>
            </div>
            <div className="mt-20">
                <AsSeenOn />
            </div>
            <NotificationCard />
            {giftCardsError && (
                <AlertModal
                    open={giftCardsError}
                    onClose={setGiftCardsError}
                    title="Error"
                >
                    <div className="flex flex-col mt-4">
                        <span className="text-sm">{giftCardsErrorMessage}</span>
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                dispatch(resetGiftCardsError());
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
            {(openLoginModal || giftCardsActivateSuccess) && (
                <AlertModal
                    open={openLoginModal || giftCardsActivateSuccess}
                    onClose={setOpenLoginModal || setSuccess}
                    title={openLoginModal ? 'Error' : 'Success'}
                >
                    {openLoginModal ? (
                        <div className="flex flex-col mt-4">
                            <span className="text-sm">
                                You must be logged in to claim your gift card.
                            </span>
                            <div className="py-2 mt-2 sm:mx-auto sm:w-full sm:max-w-lg">
                                <Formik<LoginFormModel>
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    validationSchema={loginFormSchema}
                                    onSubmit={handleSubmitLogin}
                                >
                                    <SignInFormModal />
                                </Formik>
                            </div>
                            <Button
                                type="button"
                                className="flex justify-center bg-black !font-neue text-base font-medium normal-case"
                                onClick={() => {
                                    setOpenLoginModal(false);
                                    navigate('/get-started');
                                }}
                            >
                                Create an account
                            </Button>
                        </div>
                    ) : (
                        <div className="flex flex-col mt-4">
                            <span className="text-sm">
                                Your card has been successfully activated.
                            </span>
                            <Button
                                type="button"
                                className="mt-4 flex justify-center bg-black !font-neue text-base font-medium normal-case"
                                onClick={() => {
                                    dispatch(resetGiftCardsSuccess());
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    )}
                </AlertModal>
            )}
        </Wrapper>
    );
}
