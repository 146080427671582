import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BoxCTACard from '@/components/cards/BoxCTACard';
import BoxSizeCTACard from '@/components/cards/BoxSizeCTACard';
import HowItWorksBoxes from '@/components/cards/HowItWorksBoxes';
import ProductCarrousel from '@/components/carrousel/ProductCarrousel';
import Accordion from '@/components/common/Accordion';
import AsSeenOn from '@/components/common/AsSeenOn';
import Button from '@/components/common/Button';
import {
    BestValueText,
    FreeFromNasties,
    FreeFromNastiesReverse,
    WildCaughtIcon,
} from '@/components/common/Icons';
import SectionTitle from '@/components/common/SectionTitle';
import Step from '@/components/common/Step';
import Wrapper from '@/components/common/Wrapper';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';

const Certified = `${window.cdn}CertifiedHum.png`;
const BeefImageLabel = `${window.cdn}beefProgram.png`;
const Guaranteed = `${window.cdn}guaranteed-crow.png`;
const MeatCarrousel1 = `${window.cdn}homepage/chicken-breast-550g_footer.jpeg`;
const MeatCarrousel2 = `${window.cdn}homepage/australian-wild-caught-large-cooked-ocean-king-prawns-250g_footer.jpeg`;
const MeatCarrousel4 = `${window.cdn}homepage/osso-bucco-500g_footer.jpeg`;
const MeatCarrousel5 = `${window.cdn}homepage/scotch-fillet-steak-300g_footer.jpeg`;
const MeatCarrousel6 = `${window.cdn}homepage/rump-steak-350g_footer.jpeg`;
const MeatCarrousel7 = `${window.cdn}homepage/nitrate-free-smoked-bacon-shortcut-180g_footer.jpeg`;
const MeatCarrousel8 = `${window.cdn}homepage/beef-blade-roast-1kg_footer.jpeg`;
const MeatCarrousel9 = `${window.cdn}homepage/wild-sockeye-salmon-150g_footer.jpeg`;
const MeatCarrousel10 = `${window.cdn}homepage/australian-wild-caught-barramundi-200g_footer.jpeg`;
const Step1 = `${window.cdn}howitworks/step-1.jpg`;
const Step2 = `${window.cdn}howitworks/step-2.jpg`;
const Step3 = `${window.cdn}howitworks/step-3.jpg`;
const MeatBox2 = `${window.cdn}meatbox2.png`;
const MeatBox = `${window.cdn}meatbox.png`;
const FreeShipping = `${window.cdn}badge-free-delivery.png`;
const OGImage = `${window.cdn}smo/SMO-how-it-works.png`;

const routes = [
    {
        route: '/how-it-works',
        title: 'How it works',
    },
];

const meatSlider = [
    {
        id: 1,
        name: 'Chicken Breast 550g',
        img: MeatCarrousel1,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 2,
        name: 'Australian Wild Caught Large Cooked Ocean King Prawns 250g',
        img: MeatCarrousel2,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 3,
        name: 'Osso Bucco 500g',
        img: MeatCarrousel4,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 4,
        name: 'Scotch Fillet Steak 300g',
        img: MeatCarrousel5,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 5,
        name: 'Rump Steak 350g',
        img: MeatCarrousel6,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 6,
        name: 'Nitrate Free Smoked Bacon Shortcut 180g',
        img: MeatCarrousel7,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 7,
        name: 'Beef Blade Roast 1kg',
        img: MeatCarrousel8,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 8,
        name: 'Wild Sockeye Salmon 150g',
        img: MeatCarrousel9,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 9,
        name: 'Australian Wild Caught Barramundi 200g',
        img: MeatCarrousel10,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
];
const boxes = [
    {
        id: 1,
        title: 'Custom Box',
        bestValue: true,
        caught: false,
        name: 'Create your perfect box by choosing from more than 40 high-quality cuts.',
        description:
            '<ul><li>Up to 6kg of high-quality meat</li><li>$159 per month</li><li>Skip, pause, or cancel anytime</li></ul>',
        moreTitle: 'MORE ABOUT THE CUSTOM BOX',
        moreDescription:
            '<ul><li>Wild-caught seafood</li><li>100% grass-fed, grass-finished beef & lamb</li><li>FREE-RANGE & PASTURE RAISED CHICKEN & PORK</li><li>20% more meat than our curated boxes</li><li>Update your cuts in every order</li><ul>',
    },
    {
        id: 2,
        title: 'Salmon Box',
        bestValue: false,
        caught: true,
        name: 'You’ll never have to eat farmed salmon again! Enjoy a curated selection of high-quality, sashimi-grade, 100% wild sockeye salmon, sourced from sustainable fisheries in Canada.',
        description:
            '<ul><li>10 Individually-sealed portions</li><li>$159 per month</li><li>Skip, pause, or cancel anytime</li></ul>',
        moreTitle: 'What’s in the box this month?',
        note: '*Note: We can not ship 100% Wild Salmon to Tasmania, due to State quarantine regulations.',
        smallPackageId: '17e0c60d-af0a-31f4-a9d1-75d644cc25d0',
        largePackageId: '2b1070e5-9a20-35c9-bd14-87f8f6e448be',
    },
    {
        id: 3,
        title: 'Beef Box',
        bestValue: false,
        caught: false,
        name: 'Enjoy a curated selection of 100% grass-fed, grass-finished beef.',
        description:
            '<ul><li>Up to 5kg of high-quality meat</li><li>$159 per month</li><li>Skip, pause, or cancel anytime</li></ul>',
        moreTitle: 'What’s in the box this month?',
        smallPackageId: 'ecb78c5d-2a7f-369d-9c2a-59f5316216fd',
        largePackageId: 'ca510033-78d0-3d00-ae3e-df8b433c706c',
    },
    {
        id: 4,
        title: 'Beef & Chicken Box',
        bestValue: false,
        caught: false,
        name: 'Enjoy a curated selection of 100% grass-fed, grass-finished beef and free-range & pasture raised chicken.',
        description:
            '<ul><li>Up to 5kg of high-quality meat</li><li>$159 per month</li><li>Skip, pause, or cancel anytime</li></ul>',
        moreTitle: 'What’s in the box this month?',
        smallPackageId: '725660fc-0c6e-34dc-b936-e18dab1c0c68',
        largePackageId: '8d94e9fc-df1a-38bc-b1c4-d5d1a3eb4059',
    },
    {
        id: 5,
        title: 'Beef & Pork Box',
        bestValue: false,
        caught: false,
        name: 'Enjoy a curated selection of 100% grass-fed, Grass-finished beef and free-range, Pasture raised pork.',
        description:
            '<ul><li>Up to 5kg of high-quality meat</li><li>$159 per month</li><li>Skip, pause, or cancel anytime</li></ul>',
        moreTitle: 'What’s in the box this month?',
        smallPackageId: '50dadae2-f8ee-383a-a000-000c5535aa45',
        largePackageId: 'dc28d3ea-56b9-3f8e-b84f-99b3049ef4a7',
    },
    {
        id: 6,
        title: 'Mixed Box',
        bestValue: false,
        caught: false,
        name: 'Enjoy a selection of 100% grass-fed, grass-finished beef, free-range & pasture raised chicken and pork.',
        description:
            '<ul><li>Up to 5kg of high-quality meat</li><li>$159 per month</li><li>Skip, pause, or cancel anytime</li></ul>',
        moreTitle: 'What’s in the box this month?',
        smallPackageId: '117733aa-85b7-3b08-bc43-cf40a51c706e',
        largePackageId: 'bd080e49-d237-38e8-ae84-711abae71a74',
    },
    {
        id: 7,
        title: 'Value Box',
        bestValue: false,
        caught: false,
        name: 'Enjoy a selection of 100% grass-fed, grass-finished beef, free-range & pasture raised chicken and pork.',
        description:
            '<ul><li>Up to 5kg of high-quality meat</li><li>$139 per month</li><li>Skip, pause, or cancel anytime</li></ul>',
        moreTitle: 'What’s in the box this month?',
        smallPackageId: 'ee4070a2-6ca4-37d8-93be-9e5412e019f1',
        largePackageId: 'be047a76-3dd9-3c31-811b-c8c64d222ac2',
    },
];

export default function HowItWorksPage() {
    return (
        <>
            <Helmet>
                <title>How it works | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="How it works | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <Wrapper className="px-8 pt-8 pb-12 bg-squared md:pb-24">
                <div className="lg:mx-7">
                    <Breadcrumbs crumbs={routes} />
                </div>
                <div className="w-full max-w-2xl mx-auto">
                    <h2 className="mb-6 text-4xl font-bold text-center mt-14 sm:text-5xl">
                        OUR PROMISE
                    </h2>
                    <p className="m-auto mb-6 text-lg text-center leading-6">
                        Our meat & wild-caught seafood are free from nasties and
                        sourced from trusted partners that never cut corners.
                    </p>
                    <h3 className="block mb-6 text-center sm:hidden">
                        Products are:
                    </h3>
                    <ul className="px-6 mb-5 ml-3 text-sm font-normal tracking-wide uppercase list-check leading-6 sm:p-0 sm:text-base md:ml-0">
                        <li className="mb-5">
                            100% grass-fed, grass-finished, free range, pasture
                            raised;
                        </li>
                        <li className="relative mb-5 ">
                            Free from antibiotics and hormones used for growth
                            promotion;
                            <FreeFromNasties className="absolute left-[640px] top-[-70px] hidden lg:block" />
                        </li>
                        <li className="mb-5">
                            Humanely raised and handled; and
                        </li>
                        <li className="relative">
                            Sustainably sourced.
                            <FreeFromNastiesReverse className="absolute left-[50px] block lg:hidden" />
                        </li>
                    </ul>
                    <div className="relative flex items-center mt-20 justify-evenly lg:mt-12">
                        <img
                            className="h-[48px] w-[58px] sm:h-[69px] sm:w-[85px]"
                            src={BeefImageLabel}
                            alt="Never Ever Beef Program"
                        />
                        <img
                            className="h-[47px] w-[70px] sm:h-[66px] sm:w-[99px]"
                            src={Certified}
                            alt="Certified Humane Raised & Handled"
                        />
                        <img
                            className="h-[52px] w-[52px] sm:h-[82px] sm:w-[82px]"
                            src={Guaranteed}
                            alt="Satisfaction Guaranteed"
                        />
                    </div>
                    <p className="m-auto mt-10 text-xs text-center font-neue">
                        * Certified Humane - Meets the Humane Farm Animal Care
                        Program standards. Which include nutritious diet without
                        antibiotics, or hormones, animals raised with shelter,
                        resting areas, sufficient space and the ability to
                        engage in natural behaviours.
                    </p>
                </div>
            </Wrapper>
            <div className="border-t-[7px] border-b-[7px] border-black">
                {/* Step 1 - Flexibility */}
                <div className="pb-12 lg:pb-0">
                    <Step
                        title="FLEXIBILITY"
                        text="Short on time? Let us surprise you with a changing array of products when you select a curated box. Alternatively, choose your own high-quality cuts when you select the custom box."
                        imageSrc={Step1}
                        imageAlt="A box of mixed protein ready to be delivered"
                        titleClassName='relative lg:after:absolute lg:after:top-[-40px] lg:after:content-[url("../svg/save-time.svg")]'
                        descriptionClassName='relative after:left-[0px] after:absolute md:after:left-[120px] after:bottom-[-70px] after:content-[url("../svg/save-time-reverse.svg")] lg:after:content-[""]'
                    />
                </div>
                {/* Step 2 - Value */}
                <Step
                    title="VALUE"
                    text="By joining our community, you're set to benefit, and we love to reward those who support us. Get access to our exclusive member deals, have access to our add-on menu and receive free gifts."
                    imageSrc={Step2}
                    imageAlt="Someone unpacking a valuable box of protein ready to be served"
                    imageLeft
                    titleClassName='relative lg:after:absolute lg:after:top-[-40px] after:content-[url("../svg/save-money.svg")]'
                />
                {/* Step 3 - Convenience */}
                <Step
                    title="CONVENIENCE"
                    text="Have your box delivered to your door at the frequency you want, on a day that suits you."
                    imageSrc={Step3}
                    imageAlt="An order at someone's doorstep ready to be served"
                    titleClassName='relative before:absolute before:top-[-30px] md:before:top-[-55px] before:content-[url("../svg/no-stress-reverse.svg")] lg:before:content-[""] pt-8 sm:pt-0'
                    descriptionClassName='relative lg:after:absolute lg:after:left-[-50px] lg:after:content-[url("../svg/no-stress.svg")]'
                />
            </div>
            <div className="flex flex-col items-center py-16 bg-squared">
                <h2 className="mb-24 text-4xl font-medium uppercase sm:mb-20 lg:mb-14">
                    Our Boxes
                </h2>
                <Wrapper className="relative z-10">
                    <Accordion>
                        <div>
                            {boxes.map((e, i) => (
                                <Accordion.Section
                                    value={String(e.id)}
                                    key={e.id}
                                    title={e.title}
                                    left={
                                        i === 0 ? (
                                            <>
                                                {/* <BestValueLeftArrow className="absolute hidden -mb-8 bottom-full right-full lg:block" /> */}
                                                <BestValueText className="absolute right-9 top-[60%] h-[26px] w-[90px] -translate-y-1/2 sm:right-20 sm:h-[35px] sm:w-[120px]" />
                                                <img
                                                    src={FreeShipping}
                                                    alt="A ribbon that show free shipping"
                                                    className="absolute bottom-full right-full -z-10 -mr-32 -mb-16 h-auto max-w-[190px] sm:-mr-20"
                                                />
                                            </>
                                        ) : (
                                            e.caught && (
                                                <WildCaughtIcon className="absolute -bottom-[60px] -left-[200px] hidden lg:block" />
                                            )
                                        )
                                    }
                                >
                                    <HowItWorksBoxes
                                        key={`boxes-${e.id}`}
                                        title={e.title}
                                        bestValue={e.bestValue}
                                        name={e.name}
                                        description={e.description}
                                        moreTitle={e.moreTitle}
                                        moreDescription={e.moreDescription}
                                        note={e.note}
                                        smallPackageId={e.smallPackageId}
                                        largePackageId={e.largePackageId}
                                    />
                                    {/* <div className="flex flex-col items-center">
                                        <div className="col-start-1">
                                            <div className="flex items-start space-x-6">
                                                <h3 className="text-2xl font-bold tracking-wide uppercase">
                                                    {e.title}
                                                </h3>
                                                {e.bestValue && (
                                                    <MostPopularIcon className="!-mr-5 !ml-2 sm:!ml-6" />
                                                )}
                                            </div>
                                            <div className="lg:grid lg:grid-cols-2">
                                                <div>
                                                    <p className="pt-3 pb-6 text-lg">
                                                        {e.name}
                                                    </p>
                                                    <div
                                                        className="prose col-span-2 max-w-none text-sm font-normal uppercase leading-6 text-black sm:!leading-7"
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                            __html: e.description,
                                                        }}
                                                    />
                                                </div>
                                                <div className="pt-6 lg:-mt-6 lg:ml-7 lg:pt-0">
                                                    <h4 className="mb-3 text-xs font-bold tracking-widest uppercase font-agrandir">
                                                        {e.moreTitle}
                                                    </h4>
                                                    <div
                                                        className="prose col-span-2 max-w-none list-disc text-sm font-normal uppercase leading-6 text-black sm:!leading-7"
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                            __html: e.moreDescription,
                                                        }}
                                                    />
                                                    {e.note && (
                                                        <p className="pt-3 text-xs font-bold">
                                                            {e.note}
                                                        </p>
                                                    )}
                                                    {e.smallPackageId && (
                                                        <span>
                                                            {e.smallPackageId}
                                                        </span>
                                                    )}
                                                    {e.largePackageId && (
                                                        <span>
                                                            {e.largePackageId}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <Button asChild>
                                            <Link
                                                to="/get-started"
                                                className="py-4 m-0 mt-8 text-center sm:px-14"
                                            >
                                                Choose&nbsp;your&nbsp;plan
                                            </Link>
                                        </Button>
                                    </div> */}
                                </Accordion.Section>
                            ))}
                        </div>
                    </Accordion>
                </Wrapper>
            </div>
            <div className="flex flex-col pb-16 bg-squared sm:pt-16 sm:pb-20">
                <div className="m-auto w-[80%] md:w-[100%]">
                    <SectionTitle title="CHOOSE YOUR&nbsp;BOX" />
                </div>
                <h4
                    className={`relative m-auto mb-12 w-[80%] text-center before:absolute before:top-[10px] before:left-[-35px] before:hidden before:h-[40px] before:w-[39px] before:bg-[url("../svg/arrow-down-left.svg")] before:bg-cover before:bg-no-repeat before:content-[''] after:absolute after:top-[10px]  after:right-[-35px] after:hidden after:h-[40px] after:w-[39px] after:bg-[url("../svg/arrow-down-right.svg")] after:bg-no-repeat after:content-[''] md:max-w-[425px] before:md:block after:md:block `}
                >
                    Here&apos;s what box types we currently have available…
                </h4>
                <div className="flex flex-col px-6 pt-2 m-auto md:flex-row">
                    <div className="mr-0">
                        <BoxCTACard
                            value="custom"
                            title="Custom box"
                            text="Build your own box that suits your lifestyle. From meal prep to entertaining, we've got you covered. You'll have endless variety when you select from 40+ cuts, with the option to switch it up every order."
                            image={MeatBox}
                            imageAlt="A person puts meat in a box"
                            withButton
                        >
                            <BoxSizeCTACard
                                title="SMALL BOX"
                                price="from $159/box"
                            >
                                <li className="block pb-2 -ml-4 text-xs font-bold uppercase md:hidden">
                                    From $159/box
                                </li>
                                <li>2-3 PEOPLE</li>
                                <li>UP TO 6KG OF MEAT</li>
                                <li>FREE SHIPPING</li>
                            </BoxSizeCTACard>
                            <BoxSizeCTACard
                                title="LARGE BOX"
                                price="from $269/box"
                                svg
                            >
                                <li className="block pb-2 -ml-4 text-xs font-bold uppercase md:hidden">
                                    From $269/box
                                </li>
                                <li>4-5 PEOPLE</li>
                                <li>UP TO 11KG OF MEAT</li>
                                <li>FREE- SHIPPING</li>
                            </BoxSizeCTACard>
                        </BoxCTACard>
                    </div>
                    <div className="ml-0 md:ml-4 lg:ml-8">
                        <BoxCTACard
                            value="curated"
                            title="Curated boxes"
                            text="Love a surprise, or simply too busy? Let us handle the details with our curated boxes. Contents change monthly, so you'll never be bored with the same meals on repeat. This is a great option to explore different cuts of high-quality meat."
                            image={MeatBox2}
                            imageAlt="A person puts meat in a box"
                            withButton
                        >
                            <BoxSizeCTACard
                                title="SMALL BOX"
                                price="from $139/box"
                            >
                                <li className="block pb-2 -ml-4 text-xs font-bold uppercase md:hidden">
                                    From $139/box
                                </li>
                                <li>2-3 PEOPLE</li>
                                <li>UP TO 5KG OF MEAT</li>
                                <li>FREE SHIPPING</li>
                            </BoxSizeCTACard>
                            <BoxSizeCTACard
                                title="LARGE BOX"
                                price="from $199/box"
                            >
                                <li className="block pb-2 -ml-4 text-xs font-bold uppercase md:hidden">
                                    From $199/box
                                </li>
                                <li>3-4 PEOPLE</li>
                                <li>UP TO 9KG OF MEAT</li>
                                <li>FREE SHIPPING</li>
                            </BoxSizeCTACard>
                        </BoxCTACard>
                    </div>
                </div>
            </div>
            <div className="home-page-carrousel bg-squared">
                <div className="pb-2 sm:pb-8">
                    <SectionTitle
                        title="FILLED WITH QUALITY PRODUCTS"
                        subtitle="All our meat and wild-caught seafood is free from antibiotics & hormones used for growth promotion. We offer certified humanely raised and sustainably sourced, non-GMO products."
                        bold
                        uppercase
                    />
                </div>
                <ProductCarrousel products={meatSlider} />
                <div className="flex flex-col items-center mt-12">
                    <Button asChild>
                        <Link className="px-12 py-5" to="/get-started">
                            Build your box
                        </Link>
                    </Button>
                </div>
            </div>
            <AsSeenOn />
        </>
    );
}
