import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCarrousel from '@/components/carrousel/ProductCarrousel';
import AsSeenOn from '@/components/common/AsSeenOn';
import Button from '@/components/common/Button';
import {
    BrittanySignatureIcon,
    ChrisSignatureIcon,
    JustinSignatureIcon,
    RatingStarIcon,
} from '@/components/common/Icons';
import SectionTitle from '@/components/common/SectionTitle';
import Step from '@/components/common/Step';
import { useHomepage } from '@/services/homepageService';
import { breakpointsPx } from '@/utils/useBreakPoints';

const Carrousel2 = `${window.cdn}homepage/carrousel-meat.jpg`;
const Carrousel1 = `${window.cdn}homepage/carrousel-stove.jpg`;
const Carrousel2Mobile = `${window.cdn}homepage/carrousel-meat-mobile.jpg`;
const Carrousel1Mobile = `${window.cdn}homepage/carrousel-stove-mobile.jpg`;
const MeatCarrousel1 = `${window.cdn}homepage/chicken-breast-550g_footer.jpeg`;
const MeatCarrousel2 = `${window.cdn}homepage/australian-wild-caught-large-cooked-ocean-king-prawns-250g_footer.jpeg`;
const MeatCarrousel4 = `${window.cdn}homepage/osso-bucco-500g_footer.jpeg`;
const MeatCarrousel5 = `${window.cdn}homepage/scotch-fillet-steak-300g_footer.jpeg`;
const MeatCarrousel6 = `${window.cdn}homepage/rump-steak-350g_footer.jpeg`;
const MeatCarrousel7 = `${window.cdn}homepage/nitrate-free-smoked-bacon-shortcut-180g_footer.jpeg`;
const MeatCarrousel8 = `${window.cdn}homepage/beef-blade-roast-1kg_footer.jpeg`;
const MeatCarrousel9 = `${window.cdn}homepage/wild-sockeye-salmon-150g_footer.jpeg`;
const MeatCarrousel10 = `${window.cdn}homepage/australian-wild-caught-barramundi-200g_footer.jpeg`;
const InstagramPlaceholder01 = `${window.cdn}homepage/instagram-placeholder-01.jpg`;
const InstagramPlaceholder02 = `${window.cdn}homepage/instagram-placeholder-02.jpg`;
const InstagramPlaceholder03 = `${window.cdn}homepage/instagram-placeholder-03.jpg`;
const InstagramPlaceholder04 = `${window.cdn}homepage/instagram-placeholder-04.jpg`;
const InstagramPlaceholder05 = `${window.cdn}homepage/instagram-placeholder-05.jpg`;
const Stain = `${window.cdn}homepage/stain.png`;
const Step1 = `${window.cdn}homepage/step-1.jpg`;
const Step2 = `${window.cdn}homepage/step-2.jpg`;
const Step3 = `${window.cdn}homepage/step-3.jpg`;
const Step4 = `${window.cdn}homepage/step-4.jpg`;
const PigImg = `${window.cdn}pig.png`;
const ThumbUpImg = `${window.cdn}thumb-up.png`;
const TripleAImg = `${window.cdn}triple-a.png`;
const OGImage = `${window.cdn}smo/SMO-homepage.png`;

const meatSlider = [
    {
        id: 1,
        name: 'Chicken Breast 550g',
        img: MeatCarrousel1,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 2,
        name: 'Australian Wild Caught Large Cooked Ocean King Prawns 250g',
        img: MeatCarrousel2,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 3,
        name: 'Osso Bucco 500g',
        img: MeatCarrousel4,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 4,
        name: 'Scotch Fillet Steak 300g',
        img: MeatCarrousel5,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 5,
        name: 'Rump Steak 350g',
        img: MeatCarrousel6,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 6,
        name: 'Nitrate Free Smoked Bacon Shortcut 180g',
        img: MeatCarrousel7,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 7,
        name: 'Beef Blade Roast 1kg',
        img: MeatCarrousel8,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 8,
        name: 'Wild Sockeye Salmon 150g',
        img: MeatCarrousel9,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
    {
        id: 9,
        name: 'Australian Wild Caught Barramundi 200g',
        img: MeatCarrousel10,
        alt: 'ButcherCrowd - Meat Carrousel',
        isNew: false,
    },
];

const whyUs = [
    {
        img: ThumbUpImg,
        alt: 'Thumbs up drawing',
        title: 'Super Convenient',
        text: 'Shipping is always FREE. We deliver to your door on your schedule, and you can skip a month or cancel anytime.',
    },
    {
        img: PigImg,
        alt: 'Piggy bank drawing',
        title: 'Excellent Value',
        text: 'Get a range of high-quality cuts, from beef mince and steaks to 100% wild sockeye salmon at amazing value.',
    },
    {
        img: TripleAImg,
        alt: 'AAA',
        title: 'High-Quality Products',
        text: "We're committed to quality. We offer 100% grass-fed beef & lamb, free-range & pasture raised chicken and pork & wild-caught seafood.",
    },
];

const stains = [
    <img
        src={Stain}
        alt=""
        className="absolute rotate-45 md:left-8 lg:left-12"
    />,
    <img
        src={Stain}
        alt=""
        className="æ-rotate-12 absolute top-52 md:top-60 lg:top-48"
    />,
    <img
        src={Stain}
        alt=""
        className="absolute left-10 -top-20 rotate-[24deg] -scale-x-100 -scale-y-100 md:-top-16 lg:-top-24"
    />,
];

const testimonials = [
    {
        text: 'Love my ButcherCrowd Box, I never run out of ideas for my evening meals!',
        signature: ChrisSignatureIcon,
        by: 'Chris',
        stain: stains[0],
    },
    {
        text: 'Perfect for my family of 5! I know my kids are getting good quality meat in their diet, and I spend half the time in the supermarket now!',
        signature: BrittanySignatureIcon,
        by: 'Brittany',
        stain: stains[1],
    },
    {
        text: "It's super convenient, tasty tender quality meat that is grown and fed the way nature intended. So good we're now getting 2 boxes a month!",
        signature: JustinSignatureIcon,
        by: 'Justin',
        stain: stains[0],
    },
];

const social = [
    {
        id: 1,
        image: InstagramPlaceholder01,
        alt: '',
        href: 'https://www.instagram.com/p/Cna2WoHNZbd/?hl=en',
    },
    {
        id: 2,
        image: InstagramPlaceholder02,
        alt: '',
        href: 'https://www.instagram.com/p/CoEPFztgbE1/?hl=en',
    },
    {
        id: 3,
        image: InstagramPlaceholder03,
        alt: '',
        href: 'https://www.instagram.com/p/CoKFWrNubLQ/?hl=en',
    },
    {
        id: 4,
        image: InstagramPlaceholder04,
        alt: '',
        href: 'https://www.instagram.com/p/Crwp5coM_RV/?hl=en',
    },
    {
        id: 5,
        image: InstagramPlaceholder05,
        alt: '',
        href: 'https://www.instagram.com/p/Cql6JdsOHgc/?hl=en',
    },
];

export default function HomePage() {
    const { homepageData } = useHomepage();

    const bullets = homepageData?.hero?.list;

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    ButcherCrowd | Meat & Seafood Delivery Subscription
                </title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="ButcherCrowd | Meat & Seafood Delivery Subscription"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            {/* Hero section */}
            {/* @TODO animation on scroll */}
            <div className="relative flex  h-[640px] max-h-[1300px] w-full xs:h-[780px] sm:aspect-[9_/_12] sm:h-auto md:aspect-[5_/_3] lg:aspect-[2_/_1]">
                {homepageData?.hero?.images?.mobile && (
                    <img
                        src={homepageData?.hero?.images?.mobile?.image}
                        className="object-cover object-bottom w-full h-full md:hidden"
                        alt={homepageData?.hero?.images?.mobile?.alt}
                    />
                )}
                {homepageData?.hero?.images?.desktop && (
                    <img
                        src={homepageData?.hero?.images?.desktop?.image}
                        className="hidden object-cover object-top w-full h-full md:block"
                        alt={homepageData?.hero?.images?.desktop?.alt}
                    />
                )}
                <div className="absolute top-12 left-[50%] mx-auto flex w-[calc(100%_-_2rem)] max-w-md -translate-x-[50%] flex-col space-y-3 md:left-8 md:bottom-12 md:right-1/2 md:my-auto md:translate-x-0 md:justify-center xl:max-w-[34rem] 2xl:max-w-2xl">
                    {homepageData?.hero?.title && (
                        <p className="text-2xl font-bold xs:text-3xl md:mr-8 md:text-4xl xl:text-5xl 2xl:text-6xl">
                            {homepageData?.hero?.title}
                        </p>
                    )}
                    {homepageData?.hero?.description && (
                        <p className="max-w-[26rem] text-sm lg:text-base xl:max-w-[30rem]">
                            {homepageData?.hero?.description}
                        </p>
                    )}

                    {bullets && (
                        <ul className="mb-1 text-xs font-normal tracking-wide list-checkbox leading-5 sm:p-0 xl:text-sm ">
                            {bullets.map((text: any, index: any) => (
                                <li
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-[length:15px_13px] bg-left  bg-no-repeat pl-6"
                                >
                                    {text.bulletText}
                                </li>
                            ))}
                        </ul>
                    )}
                    {homepageData?.hero?.cta && (
                        <div>
                            <Button asChild>
                                <Link to="/get-started">
                                    {homepageData?.hero?.cta}
                                </Link>
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            {/* Animated carrousel */}
            <div className="max-w-[100vw] overflow-hidden">
                <div className="flex h-[57px] w-[320rem] items-center bg-secondary">
                    <div
                        className="flex flex-row items-center w-full overflow-x-hidden text-sm font-bold text-center text-white no-whitespace animate-ticker font-agrandir child:flex-1"
                        aria-hidden="true"
                    >
                        {new Array(6)
                            .fill([
                                'HUMANELY RAISED',
                                'SUSTAINABLY SOURCED',
                                'FREE OF NASTIES',
                            ])
                            .flat()
                            .map((text, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <p key={i}>{text}</p>
                            ))}
                    </div>
                </div>
            </div>
            <div className="border-b-[7px] border-black">
                {/* Step 1 - We source */}
                <Step
                    title="WE SOURCE"
                    text="We source our meat & wild-caught seafood, from trusted partners and Australian farming and fishing families with the highest standards for quality."
                    button={
                        <div>
                            <Button secondary>
                                <Link to="/sourcing">Learn more</Link>
                            </Button>
                        </div>
                    }
                    imageSrc={Step1}
                    imageAlt="A fisherman shaking hands with a farmer"
                />
                {/* Step 2 - Select your box */}
                <Step
                    title="SELECT YOUR BOX"
                    text="Select the box that suits you. Take advantage of our
                            free shipping and load your box with optional add
                            ons!"
                    imageSrc={Step2}
                    imageAlt="A laptop with the butchercrowd website next to packaged meats and a butchercrowd box"
                    imageLeft
                />
                {/* Step 3 - We deliver to your door */}
                <Step
                    title="WE DELIVER TO YOUR DOOR"
                    text="Your meat & seafood is frozen & carefully packaged in an environmentally friendly, biodegradable, insulated box and delivered to your door."
                    imageSrc={Step3}
                    imageAlt="A butcher crowd box on a door step"
                />
                {/* Step 4 - Enjoy! */}
                <Step
                    title="ENJOY !"
                    text="Enjoy sharing a meal with family and friends, knowing your premium meat and seafood has been sourced from Australian farming and fishing families, and trusted partners who share our high standards for quality."
                    imageAlt="Two delicious looking medium rare steaks"
                    imageSrc={Step4}
                    button={
                        <div>
                            <Button secondary>
                                <a href="get-started">Fill your box</a>
                            </Button>
                        </div>
                    }
                    imageLeft
                />
            </div>
            <div className="bg-squared">
                <div className="py-16 home-page-carrousel pt-28">
                    <SectionTitle
                        title="High-quality products"
                        subtitle="All our meat and wild-caught seafood is free from antibiotics & hormones used for growth promotion. We offer certified humanely raised and sustainably sourced, non-GMO products."
                        bold
                        uppercase
                    />
                    <ProductCarrousel products={meatSlider} />
                    <div className="flex flex-col items-center mt-16">
                        <Button asChild>
                            <Link to="/get-started">Build your box</Link>
                        </Button>
                    </div>
                </div>
                <div className="px-8 pt-14 md:mx-auto md:grid md:grid-cols-3 md:gap-4 md:px-10 lg:container lg:gap-6 lg:px-8 xl:max-w-7xl xl:gap-12">
                    {whyUs.map(({ title, img, alt, text }) => (
                        <div
                            key={title}
                            className="px-5 py-3 pb-8 mb-8 overflow-hidden bg-white border border-black why-us-card md:px-3 lg:px-4 lg:py-8 xl:px-7"
                        >
                            <div className="flex flex-col items-start justify-center text-center sm:flex-row sm:text-left md:flex-col md:text-center">
                                <div className="flex w-full flex-col items-center justify-end sm:w-[120px] md:w-full">
                                    <img
                                        src={img}
                                        alt={alt}
                                        className="w-[120px]"
                                    />
                                </div>
                                <div className="mx-auto mt-6 w-[300px] max-w-[100%] sm:ml-4 sm:w-[calc(100%_-_100px)] md:ml-auto md:mt-3 md:w-full lg:mt-6 lg:ml-0">
                                    <h4 className="mb-2 font-bold font-agrandir">
                                        {title}
                                    </h4>
                                    <p>{text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="my-16 border-b-[7px] border-t-4 border-black">
                    <Swiper
                        pagination
                        modules={[Pagination, Autoplay]}
                        slidesPerView={1}
                        autoplay={{ delay: 5000, disableOnInteraction: true }}
                        grabCursor
                        className="aspect-[38_/_42] sm:aspect-[3_/_1]"
                        loop
                    >
                        <SwiperSlide>
                            <img
                                src={Carrousel1}
                                alt="Steak cooking on a gas stove"
                                className="hidden w-full sm:block"
                            />
                            <img
                                src={Carrousel1Mobile}
                                alt="Steak cooking on a gas stove"
                                className="block w-full sm:hidden"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                src={Carrousel2}
                                alt="Fresh salmon, chicken, steak and pork pieces on a white countertop"
                                className="hidden w-full sm:block"
                            />
                            <img
                                src={Carrousel2Mobile}
                                alt="Fresh salmon, chicken, steak and pork pieces on a white countertop"
                                className="block w-full sm:hidden"
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <SectionTitle
                    title="LOVED BY ALL."
                    subtitle="Trusted by thousands of Australians"
                    withLine
                />
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={24}
                    initialSlide={(testimonials.length - 1) / 2}
                    breakpoints={{
                        [breakpointsPx.desktopL]: {
                            slidesPerView: 3,
                            spaceBetween: 90,
                        },
                        [breakpointsPx.desktop]: {
                            slidesPerView: 3,
                            spaceBetween: 60,
                        },
                        [breakpointsPx.tabletH]: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        [breakpointsPx.tabletV]: { slidesPerView: 2 },
                    }}
                    grabCursor
                    className="h-96 !px-4 xs:h-80 sm:!px-10 lg:pointer-events-none lg:container [&_.swiper-wrapper]:items-stretch"
                >
                    {testimonials.map(
                        ({ text, signature: Signature, stain, by }) => (
                            <SwiperSlide
                                key={by}
                                className="flex  maxsm:!w-[calc(100%_-_40px)]"
                            >
                                <article
                                    key={by}
                                    className="relative flex flex-col px-8 pt-8 pb-4 my-8 overflow-hidden text-white bg-primary shadow-block"
                                >
                                    <div
                                        className="flex space-x-1.5"
                                        role="img"
                                        aria-label="5 stars"
                                    >
                                        <RatingStarIcon className="w-6 sm:w-8 xl:w-10" />
                                        <RatingStarIcon className="w-6 sm:w-8 xl:w-10" />
                                        <RatingStarIcon className="w-6 sm:w-8 xl:w-10" />
                                        <RatingStarIcon className="w-6 sm:w-8 xl:w-10" />
                                        <RatingStarIcon className="w-6 sm:w-8 xl:w-10" />
                                    </div>
                                    <div className="pt-4 text-sm font-medium">
                                        {text}
                                    </div>
                                    <Signature
                                        className="mt-5"
                                        aria-label={`- ${by}`}
                                    />
                                    {stain}
                                </article>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
                <div className="pt-4 mt-12 home-page-social-carousel">
                    <div className="mb-10">
                        <SectionTitle title="SHARED BY MANY." withLine />
                    </div>
                    <Swiper
                        slidesPerView={1.5}
                        spaceBetween={0}
                        breakpoints={{
                            [breakpointsPx.mobile]: {
                                slidesPerView: 3.2,
                                spaceBetween: 60,
                                effect: 'coverflow',
                                coverflowEffect: {
                                    rotate: 0,
                                    slideShadows: false,
                                    stretch: 20,
                                    modifier: 0.7,
                                    scale: 0.9,
                                },
                            },
                            [breakpointsPx.tabletH]: {
                                slidesPerView: 4.5,
                                spaceBetween: 60,
                                effect: 'coverflow',
                                coverflowEffect: {
                                    rotate: 0,
                                    slideShadows: false,
                                    stretch: 20,
                                    modifier: 0.7,
                                    scale: 0.9,
                                },
                            },
                        }}
                        loop
                        autoHeight
                        grabCursor
                        centeredSlides
                        modules={[EffectCoverflow]}
                        effect="coverflow"
                        coverflowEffect={{
                            modifier: 0,
                            slideShadows: false,
                        }}
                    >
                        {social.map(({ id, image, alt, href }) => (
                            <SwiperSlide key={id}>
                                <a href={href} target="_blank" rel="noreferrer">
                                    <img src={image} alt={alt} />
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="justify-center hidden mt-16 md:flex">
                        <Button>
                            <a href="https://www.instagram.com/butcher_crowd/?hl=en">
                                visit our instagram
                            </a>
                        </Button>
                    </div>
                </div>
            </div>
            <AsSeenOn />
        </>
    );
}
